<template>
  <div id="HomePage">
    <header>
      <img src="@/assets/logo1.png" width="130px" style="margin: 38px 62px 0" />
      <div class="next">
        <p>贝湾致力于为高校提供数字化教学实施与管理服务</p>
        <p>
          贝湾是一家提供优质数字内容与教&学导航系统的教育研发公司，具备教&学及职业探索理论研究及产品创新能力，正在打造基于综合型人才培养的“内容+教&学+职业探索”数字教育生态，让学校实现个性地学、差异地教、科学地评、高效地选。
        </p>
      </div>
      <div class="card">
        <div class="card_li" v-for="(item, index) in cardList" :key="index">
          <div>
            <img :src="item.icon" class="icon" />
            <div>{{ item.name }}</div>
          </div>
          <img src="@/assets/icon5.png" class="icon5" v-if="index !== 3" />
        </div>
      </div>
    </header>
    <main>
      <div class="introduce1">
        <div style=" margin: auto;width: 1200px">
          <div class="introduce1_1">
            <img src="@/assets/image1.png" class="image1" />
            <div>
              <p class="t1">知识培养路径</p>
              <p style=" font-size: 16px;font-weight: 600">
                图谱教学(KBL)|专业学科培养图谱教学系统
              </p>
              <p class="t2">
                动态解决知识体系化传授及知识再创造问题；系统包括专业培养教学方法，并将教师教学内容以“知识点”为单元的图谱形式呈现；系统通过教&学相应行为获得数据，为师生提供行动依据。该系统适用于自然、社科、人文各专业学科培养及交叉学科资源建设与重组；满足学生个性化学习；高效服务“线上+线下”混合式教学，推动高校课堂回归互动。
              </p>
            </div>
          </div>
          <img
            src="@/assets/xian.png"
            width="100%"
            height="147px"
            style="margin: 18px 0 1px"
          />
          <div class="introduce1_1">
            <div style="margin-left: 61px">
              <p class="t1" style="margin-top: 50px">实践培养路径</p>
              <p style=" font-size: 16px;font-weight: 600">
                项目制实践(PBL)|实践能力培养系统
              </p>
              <p class="t2">
                动态解决学生实践能力培养问题；系统通过学生参与项目制学习(PBL、WBL、PBE)获得学习数据，为培养动作提供判断依据。该系统可广泛应用于交叉学科的探索、基于岗位视角的职业探索，并实现学生社会化能力的培养。搭建就业与招聘之间的高效桥梁，实现人才培养与社会需求的动态结合。
              </p>
            </div>
            <img src="@/assets/image2.png" class="image2" />
          </div>
        </div>
      </div>
      <div class="introduce2">
        <p style="height: 65px"></p>
        <p class="text">我们的产品</p>
        <div style=" margin: 0 auto;width: 257px; text-align: left">
          <p style="margin: 49px 0 7px">—教学导航系统 光谱计划</p>
          <p style="margin-bottom: 57px; white-space: nowrap">
            —职业探索&就业导航系统 与光同程
          </p>
        </div>
        <img src="@/assets/image3.png" />
      </div>
    </main>
    <footer>
      <img src="@/assets/logo2.png" />
      <div>
        <p>北京贝湾教育科技有限公司</p>
        <p>地址：北京市朝阳区工体北路甲2号盈科中心A座</p>
        <p style="cursor: pointer">
          <span @click="link(1)">京 ICP 备 2023004140 号 - 2</span>
          <span style="margin-left: 25px" @click="link(2)">
            京公网安备 11010502053305号
          </span>
        </p>
      </div>
    </footer>
  </div>
</template>

<script>
export default {
  name: 'HomePage',
  data () {
    return {
      cardList: [
        { icon: require('@/assets/icon1.png'), name: '因材施教' },
        { icon: require('@/assets/icon2.png'), name: '沉浸➕体验 全场景互动' },
        { icon: require('@/assets/icon3.png'), name: '数字化赋能教学' },
        { icon: require('@/assets/icon4.png'), name: '职业探索人才培养' }
      ]
    }
  },
  methods: {
    link (val) {
      window.open(
        val === 1
          ? 'https://beian.miit.gov.cn/#/Integrated/index'
          : 'https://www.beian.gov.cn/portal/registerSystemInfo?recordcode=11010502053305'
      )
    }
  }
}
</script>

<style lang="less" scoped>
#HomePage {
  font-family: 'PingFang SC-Semibold', 'PingFang SC';
  font-weight: 400;
  color: #333333;
  header {
    position: relative;
    width: 100%;
    height: 670px;
    background: url('~@/assets/bg.png') no-repeat center / cover;
    .next {
      margin: 166px 0 0 256px;
      p {
        color: #3f414c;
        &:first-child {
          margin-bottom: 24px;
          font-size: 36px;
          font-weight: 600;
        }
        &:last-child {
          width: 648px;
          font-size: 16px;
          font-family: 'PingFang SC-Regular', 'PingFang SC';
          text-align: justify;
          color: #3f414c;
          line-height: 30px;
        }
      }
    }
    .card {
      position: absolute;
      bottom: -107px;
      left: 50%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-left: -600px;
      width: 1200px;
      height: 176px;
      border-radius: 10px;
      background: linear-gradient(180deg, #f1f5fa 0%, #ffffff 100%);
      box-shadow: 0 4px 10px 5px #f7fafe, inset 0 4px 4px 0 #ffffff;
      .card_li {
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 16px;
        text-align: center;
        &:first-child {
          margin-left: 146px;
        }
        &:last-child {
          margin-right: 146px;
        }
        .icon {
          margin-bottom: 10px;
          width: 86px;
          height: 86px;
        }
        .icon5 {
          margin: 0 35px;
          width: 63px;
          height: 37px;
        }
      }
    }
  }
  main {
    margin: 206px auto 0;
    .introduce1 {
      width: 100%;
      height: 950px;
      .introduce1_1 {
        display: flex;
        text-align: justify;
        .image1 {
          margin: 0 119px 0 97px;
          width: 437px;
          height: 317px;
        }
        .image2 {
          margin: 0 109px 0 80px;
          width: 444px;
          height: 339px;
        }
        .t1 {
          margin: 55px 0 17px;
          font-size: 28px;
          font-weight: 600;
          color: #000000;
        }
        .t2 {
          margin-top: 28px;
          font-size: 14px;
          font-family: 'PingFang SC-Regular', 'PingFang SC';
          line-height: 28px;
        }
      }
    }
    .introduce2 {
      width: 100%;
      height: 880px;
      font-size: 16px;
      text-align: center;
      background: #f6fafd;
      img {
        width: 869px;
      }
      .text {
        font-size: 28px;
        font-family: 'PingFang SC-Regular', 'PingFang SC';
        font-weight: 600;
        color: #000000;
      }
    }
  }
  footer {
    width: 100%;
    height: 270px;
    text-align: center;
    color: #ffffff;
    background: #20263a;
    img {
      margin: 52px auto 29px;
      width: 130px;
    }
    p {
      font-size: 12px;
      font-family: 'PingFang HK-Regular', 'PingFang HK';
      &:nth-child(1) {
        font-size: 16px;
      }
      &:nth-child(2) {
        margin: 13px auto 49px;
        font-family: 'PingFang HK-Light', 'PingFang HK';
        font-weight: 300;
      }
    }
  }
}
</style>
